import { ErrorBoundary } from 'react-error-boundary'
import Footer from "../Footer"
import Navbar from "../Navbar"
import { NotificationContextProvider } from '../../../context'
import PropTypes from 'prop-types';
import React from 'react';
import { navigate } from "@reach/router"
import Seo from '../../seo'
import SectionWrapper from "../../sections/SectionWrapper"
import Banner from "../../page-specific/Seamless-To-Grubhub/Banner"

const capitalizeFirstLetter = string => {
  if (typeof string === "string") {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return string;
  }
}

const IfShowHeaderAndFooter = (location) => {
  const denyLocations = [
    '/about-grubhub-corporate/', '/about-grubhub-corporate',
    '/refer-grubhub-corporate/', '/refer-grubhub-corporate',
    '/feed-digital-events/', '/feed-digital-events/',
    '/feed-remote-employees/', '/feed-remote-employees',
    '/group-ordering/', '/group-ordering',
    '/corporate-catering/', '/corporate-catering',
    '/corporate-tax-exempt-food-delivery/', '/corporate-tax-exempt-food-delivery',
    '/feed-remote-teams/', '/feed-remote-teams',
    '/group--order/', '/group--order',
    '/feed-your-digital-events/', '/feed-your-digital-events',
    '/about-grubhub-corporate-accounts/', '/about-grubhub-corporate-accounts',
    '/seamless-grubhub/', '/seamless-grubhub',
    '/self-service-information/', '/self-service-information',
    '/catering-for-corporate/', '/catering-for-corporate',
    '/amazon-business-prime/', '/amazon-business-prime',
    '/amazon-business-prime-referral/', '/amazon-business-prime-referral',
  ];
  return !denyLocations.includes(location?.pathname);
}

const Layout = ({ children, title, description, image, className, nofollow, noindex, utag_data, location, faqSeoList }) => {

  const [comingFromSeamless, setComingFromSeamless] = React.useState(false)

  const showHeaderAndFooter = IfShowHeaderAndFooter(location)

  const bannerInfo = {
    title: "Seamless Corporate is now Grubhub Corporate",
    text1: "Previously known as Seamless Corporate, Grubhub Corporate has the same great network of restaurants, convenient ordering options and fast delivery that New York teams have loved for over 20 years.",
    text2: "Discover the tasty benefits of a Corporate Account today!"
  }

  React.useEffect(() => {
    //redirects
    switch (window.location.pathname) {
      case '/resource/the-checkout-page/':
        navigate("/resource/the-checkout-page/allocations/");
        break;
      case '/resource/upgrading-from-grubhub-for-work-to-grubhub-corporate/':
        navigate("/resource/signing-in/");
        break;
      case '/resource/faqs-2/grubhub-brand-updates-faq/':
        navigate("/resource/faqs-2/grubhub-and-jet-merger-faq/");
        break;
      case '/resource/faqs-2/other/':
        navigate("/resource/signing-in/");
        break;
      case '/resource/faqs-2/':
        navigate("/resource/signing-in/");
        break;
      case '/resource/placing-an-order/':
        navigate("/resource/placing-an-order/individual-orders/");
        break;
      case '/resource/placing-an-order-2/':
        navigate("/resource/placing-an-order-2/individual-orders/");
        break;
      case '/resource/faqs-2/orders/':
        navigate("/resource/placing-an-order/individual-orders/");
        break;
      case '/resource/admin-portal/':
        navigate("/resource/signing-in/");
        break;
      case '/resource/whats-new/':
        navigate("/resource/signing-in/");
        break;
      case '/resource/the-checkout-page-2/':
        navigate("/resource/the-checkout-page-2/allocations/");
        break;
      case '/blog/5-ideas-to-virtually-celebrate-employee-appreciation-day-2021/':
        navigate("/blog/5-ideas-to-virtually-celebrate-employee-appreciation-day/");
        break;
      default:
    }

    if (typeof window !== "undefined") {
      const params = new URLSearchParams(window.location.search).get("seamless");
      // Check if the resource type in the params is different from the one set in context
      if(params === 'true'){
        setComingFromSeamless(true)
      }
    }

    if (typeof window !== 'undefined' && window.location.href.indexOf("self-service-information") === -1) {
      // Qualified Page tracking.
      const url = new URL(window.location);
      if (url && window.qualified) {
        // pageName is given the value of window.location.pathname, but in a more readable format.
        let fullPath = capitalizeFirstLetter(url.pathname.substring(1, url.pathname.length - 1).replaceAll("-", " ").replaceAll("grubhub", "Grubhub"));
        let processedPageNameForQualified = "";
        // if fullPath is just "/" it means user is on Homepage
        if (fullPath !== "/") {
          // if fullPath contains "/", it is comprized of folders and subfolders
          if (fullPath.indexOf("/") > -1) {
            let folderArrayFromFullPath = fullPath.split("/");
            processedPageNameForQualified = folderArrayFromFullPath[0];
            for (let i = 1; i < folderArrayFromFullPath.length; i++) {
              processedPageNameForQualified = processedPageNameForQualified + " - " + capitalizeFirstLetter(folderArrayFromFullPath[i]);
            }
          }
        } else {
          processedPageNameForQualified = "Homepage";
        }

        if( processedPageNameForQualified ) {
          url.searchParams.set( 'page', processedPageNameForQualified );
          window.history.replaceState( {}, '', url );
          window.qualified( 'page' );
        }
      }
    }
  },[])

  //TODO pull in notifications at this level using a query then pass down to <Navbar/>
  return (
    <ErrorBoundary FallbackComponent={FallbackComponent}>
        <div className={className}>
          <Seo title={title} description={description} image={image} nofollow={nofollow} noindex={noindex} utag_data={utag_data}/>
          {/* Pull this context out to the wrapRootElement function in gatsby-browser.js */}
          <NotificationContextProvider>
          {showHeaderAndFooter && <Navbar/>}
          </NotificationContextProvider>
          <main style={{
            minHeight:'50vh',
            }}>

              {comingFromSeamless && <Banner bannerInfo={bannerInfo} />}
              {children}

          </main>
          {showHeaderAndFooter && <Footer/>}
        </div>
    </ErrorBoundary>
  )
}

const FallbackComponent = () => {
  return (
    <SectionWrapper>
      <div className="fallback-component">
        <h2>Oh no... This wasn't supposed to happen <span role="img" aria-label="grimace">😬</span></h2>
        <p>Whatever just happened caused and error</p>
        <img src="https://media.giphy.com/media/jOpLbiGmHR9S0/giphy.gif" alt='Not good'/>
        <br/>
        <br/>
        <br/>
        <h3>Not to worry!</h3>
        <p>The devs over here have been notified and will be working diligently to find that nasty bug and squash it!</p>
        <img src="https://media.giphy.com/media/lFZKK1pINTGA8/giphy.gif" alt='Bug smash'/>
      </div>
    </SectionWrapper>
  );
}

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  noindex: PropTypes.bool,
  nofollow: PropTypes.bool,
  utag_data: PropTypes.shape({
    pageGroup: PropTypes.string.isRequired,
    subGroup: PropTypes.string.isRequired,
    pageName: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
  }).isRequired
};

Layout.defaultProps = {
  title: null,
  description: null,
  noindex: false,
  nofollow: false,
  utag_data: {
    pageGroup: "grubhub - lead generation",
    subGroup: "not set",
    pageName: "not set",
    brand: "grubhub"
  }
};

export default Layout;
